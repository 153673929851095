import React from 'react';
import { Typography, Paper, Grid, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './App.css';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import getGlobalStyles from './globalStyles';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faBuilding, faUserGraduate } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const firebaseConfig = {
  apiKey: "AIzaSyA6PwUhk-IjNp3KQXYofJ0rJqnRmP7ZtzE",
  authDomain: "webpage-2d7e9.firebaseapp.com",
  projectId: "webpage-2d7e9",
  storageBucket: "webpage-2d7e9.appspot.com",
  messagingSenderId: "807021205547",
  appId: "1:807021205547:web:57ee45ff1d1f092856b26e",
  measurementId: "G-447S87BW7G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

library.add(fab, faBuilding, faUserGraduate)

function Frontpage() {

  const useLocalStyles = makeStyles((theme) => ({
    ...getGlobalStyles(theme)
  }));


  logEvent(analytics, "frontpage");
  const classes = useLocalStyles();

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <Typography variant="h5" align="left">
            Velkommen til Mitec consulting!
          </Typography>
          <Typography variant="body1">
            <p>
              Jeg har arbejdet professionelt med IT siden 1997.
            </p>
            <p>
              I starten af karrieren som systemadministrator inden for Linux, Windows, HP, Cisco og Juniper. Siden som udvikler i primært Perl og PHP og React.
              I den sidste del af karrieren har jeg været henholdsvis CTO og CFO.
            </p>
            <p>
              Jeg har primært arbejdet med automatiseringer, integrationer og faktureringssystemer de sidste 15 år.
            </p>
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <Typography variant="h5" align="left">
            CV
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon><FontAwesomeIcon icon={faUserGraduate} /></ListItemIcon>
              <ListItemText><Typography variant="body1">Datalog med fysik som bifag fra Københavns Universitet fra 1995 til 2003</Typography></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><FontAwesomeIcon icon={faBuilding} /></ListItemIcon>
              <ListItemText>Enator (nu en del af Visma) fra 1997 til 2000</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><FontAwesomeIcon icon={faBuilding} /></ListItemIcon>
              <ListItemText>Adaptive Systems (nu 7N) fra 2000 til 2003</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><FontAwesomeIcon icon={faBuilding} /></ListItemIcon>
              <ListItemText>Telelet A/S (nu en del af TDC) fra 2003 til 2009</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><FontAwesomeIcon icon={faBuilding} /></ListItemIcon>
              <ListItemText>Uni-tel A/S fra 2009 til 2019</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><FontAwesomeIcon icon={faBuilding} /></ListItemIcon>
              <ListItemText>Mitec consulting ApS fra 2019</ListItemText>
            </ListItem>
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <Typography variant="h5" align="left">
            Kompetencer
          </Typography>
          <List>
            <ListItem>
              <ListItemText><Typography variant="body1">Sprog: PHP, Perl og React</Typography></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Økonomisystemer: E-conomic og Microsoft Dynamics NAV</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Cloud: GCP og AWS</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Databaser: MySQL/MariaDB, MongoDB, Firestore og MS SQL</ListItemText>
            </ListItem>
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <Typography variant="h5" align="left">
            Kontakt
          </Typography>
          <List>
            <ListItem>
              <ListItemText><Typography variant="body1">+45 61 48 48 61</Typography></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>misak (at) mitec-consulting (dot) dk</ListItemText>
            </ListItem>
          </List>
        </Paper>
      </Grid>
    </>
  );
}

export default Frontpage;
