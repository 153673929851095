import React from 'react'
import { AppBar, Toolbar, Typography, Paper, CssBaseline, Grid, Box, Divider, Badge, IconButton, Container, Drawer, List } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { makeStyles } from '@material-ui/core/styles'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
// import { initializeApp } from 'firebase/app'
// import { getAnalytics } from "firebase/analytics"
import { mainListItems } from './listitems'
import getGlobalStyles from './globalStyles'
// import AdSense from 'react-adsense'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faBuilding, faUserGraduate } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Frontpage from './Frontpage'
import Projects from './Projects'


// const firebaseConfig = {
//   apiKey: 'AIzaSyA6PwUhk-IjNp3KQXYofJ0rJqnRmP7ZtzE',
//   authDomain: 'webpage-2d7e9.firebaseapp.com',
//   projectId: 'webpage-2d7e9',
//   storageBucket: 'webpage-2d7e9.appspot.com',
//   messagingSenderId: '807021205547',
//   appId: '1:807021205547:web:57ee45ff1d1f092856b26e',
//   measurementId: 'G-447S87BW7G'
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

library.add(fab, faBuilding, faUserGraduate)

function App() {

  const useLocalStyles = makeStyles((theme) => ({
    ...getGlobalStyles(theme)
  }));



  const classes = useLocalStyles();

  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Mitec consulting
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{mainListItems}</List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Switch>
                <Route path="/projects">
                  <Projects />
                </Route>
                <Route path="/">
                  <Frontpage />
                </Route>
              </Switch>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography variant="body2" color="textSecondary" align="center">
                    Copyright © Mitec consulting ApS
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Box pt={4}>
            </Box>
          </Container>
        </main>
      </div>
    </Router>



  );
}

export default App;
