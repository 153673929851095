import React from 'react';
import { Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './App.css';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import getGlobalStyles from './globalStyles';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faBuilding, faUserGraduate } from '@fortawesome/free-solid-svg-icons'


const firebaseConfig = {
  apiKey: "AIzaSyA6PwUhk-IjNp3KQXYofJ0rJqnRmP7ZtzE",
  authDomain: "webpage-2d7e9.firebaseapp.com",
  projectId: "webpage-2d7e9",
  storageBucket: "webpage-2d7e9.appspot.com",
  messagingSenderId: "807021205547",
  appId: "1:807021205547:web:57ee45ff1d1f092856b26e",
  measurementId: "G-447S87BW7G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

library.add(fab, faBuilding, faUserGraduate)

function Projects() {

  const useLocalStyles = makeStyles((theme) => ({
    ...getGlobalStyles(theme)
  }));


  logEvent(analytics, "projects");
  const classes = useLocalStyles();

  return (
    <>
      <Grid item xs={12} lg={12}>
        <Paper className={classes.paper}>
          <Typography variant="h5" align="left">
            Firmainfo
          </Typography>
          <Typography variant="body1">
            <p>
              Her er samlet forskellige offentlige data om firmaer (ApS, A/S og K/S). Du kan se top 100 lister pr. kommune ud fra forskellige nøgletal (egenkapital, resultat, skat og balance), eller nationale top 10 lister. For hvert enkelt firma kan du også se informationer fra DAR som fx. højde over havets overflade, opvarmingsform eller om bygningen er fredet.
            </p>
            <p>
              Systemet er kodet i React.js og backend er Firestore, Cloud Functions og Pub/Sub - alt sammen hos Google Cloud.
            </p>
            <p>
              <a href="https://firmainfo.mitec-consulting.dk/">https://firmainfo.mitec-consulting.dk/</a>
            </p>
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Paper className={classes.paper}>
          <Typography variant="h5" align="left">
            Visualisering af data fra motorregisteret
          </Typography>
          <Typography variant="body1">
            <p>
              Grafer og trends over danske personbiler. Der er fokus på overgangen til elbiler. Der er pt. ca. 2% elbiler hvor Tesla og Volkswagen dominerer billedet.
            </p>
            <p>
              Systemet er lavet i Google Data studio med BigQuery som backend. Data fra motorregisteret bliver automatisk hentet og læst ind i BigQuery.
            </p>
            <p>
              <a href="https://datastudio.google.com/u/0/reporting/e0a484ad-a04e-4176-b749-fba2872d664a/page/p_y8zasbdopc?fbclid=IwAR3e4lW2sCCNiJBkb4VRyaFecA15CA7LZFLH49uf3h9TbXJFPu_zf_fV8Ns">Dashboard</a>
            </p>
          </Typography>
        </Paper>
      </Grid>
    </>
  );
}

export default Projects;
